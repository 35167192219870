/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"

const Pricing = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  // const [active, setActive] = useState(true)
  // const [price, setPrice] = useState(5)

  // const setPricing = () => {
  //   setActive(!active)
  //   setPrice(price === 5 ? 6 : 5)
  // }

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/pricing/",
        name: "Pricing - Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/pricing/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Document eSign plans and pricing options are available: free, pro, team, and enterprise, choose the one that works best for you",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/pricing/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO
          title="100% Free Document eSign Solution"
          description=" Document eSign plans and pricing options are available: it's free for everyone no credit card required"
          schemaMarkup={schema}
        />
        <div className="intro pricing-intro bg-blue-light position-relative">
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center pt-lg-5">
                <div className="ani-in show-on-scroll">
                  <h1 className="pt-5">Free forever for all users</h1>
                  <div className="h4 mt-4 pt-1 fw-regular">
                    We&apos;re offering free solution to everyone to scale{" "}
                    <br /> their business.
                  </div>
                  {/* <div className="d-flex justify-content-center mt-4 mb-sm-5 align-items-center">
                    <div className="pr-2">Billed monthly</div>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="billedyearly"
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        checked={active}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        // onChange={() => {
                        //   setActive(old => !old)
                        // }}
                        onChange={() => setPricing()}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="billedyearly"
                      >
                        Billed yearly
                      </label>
                    </div>
                    <div className="h6 pl-2 d-none d-sm-flex">
                      <span className="badge pricing-badge py-2 px-3 rounded-pill">
                        Save Upto 20%
                      </span>
                    </div>
                  </div> */}

                  {/* <div className="h6 pl-2 d-sm-none my-3">
                    <span className="badge pricing-badge py-2 px-3 rounded-pill">
                      Save Upto 20%
                    </span>
                  </div> */}
                </div>
                <div className="row mt-4 mb-4 mb-lg-0">
                  <div className="col-sm-12 ani-in show-on-scroll">
                    <div className="card border-0 shadow-sm pricing-plan h-100">
                      <div className="card-body text-center p-5">
                        {/* <div className="h4">Free plan</div>
                        <p className="text-muted">
                          Start now and upgrade later
                        </p>
                        <div className="free-plan-price">
                          <div className="plan-price">$0.00</div>
                        </div>
                        <a
                          href="https://app.documentesign.com/auth/signup"
                          target="_blank"
                          className="btn btn-outline-primary btn-block my-4"
                        >
                          Get started
                        </a> */}
                        <img
                          src={withPrefix("/images/free-forever.png")}
                          className="img-fluid lazyload"
                        />
                        {/* <ul className="pricing-feature-list mt-4">
                        <li> Unlimited documents</li>
                        <li>Unlimited templates</li>
                        <li>Multiple signers</li>
                        <li>Secure audit trail</li>
                        <li>Cloud Integration Support</li>
                        <li>Unlimited reports</li>
                        </ul> */}
                        <div className="free-forever-list row row-cols-1 row-cols-md-2 mt-5 mb-4">
                          <div className="col">
                            <div className="d-flex align-items-center free-forever-list-item">
                              <div className="flex-shrink-0 free-forever-list-icon">
                                <i className="fas fa-check"></i>
                              </div>
                              <div className="flex-grow-1 text-left free-forever-list-text">
                                Unlimited documents
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-flex align-items-center free-forever-list-item">
                              <div className="flex-shrink-0 free-forever-list-icon">
                                <i className="fas fa-check"></i>
                              </div>
                              <div className="flex-grow-1 text-left free-forever-list-text">
                                Unlimited templates
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-flex align-items-center free-forever-list-item">
                              <div className="flex-shrink-0 free-forever-list-icon">
                                <i className="fas fa-check"></i>
                              </div>
                              <div className="flex-grow-1 text-left free-forever-list-text">
                                Multiple signers
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-flex align-items-center free-forever-list-item">
                              <div className="flex-shrink-0 free-forever-list-icon">
                                <i className="fas fa-check"></i>
                              </div>
                              <div className="flex-grow-1 text-left free-forever-list-text">
                                Secure audit trail
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-flex align-items-center free-forever-list-item">
                              <div className="flex-shrink-0 free-forever-list-icon">
                                <i className="fas fa-check"></i>
                              </div>
                              <div className="flex-grow-1 text-left free-forever-list-text">
                                Cloud integration support
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-flex align-items-center free-forever-list-item">
                              <div className="flex-shrink-0 free-forever-list-icon">
                                <i className="fas fa-check"></i>
                              </div>
                              <div className="flex-grow-1 text-left free-forever-list-text">
                                Alerts and notifications
                              </div>
                            </div>
                          </div>
                        </div>
                        <a
                          href="https://app.documentesign.com/auth/signup"
                          target="_blank"
                          className="btn btn-primary my-4 px-5"
                        >
                          Signup now &#8594;
                        </a>

                        {/* <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            10 Documents per month
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            1 Template
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            Multiple signer
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            Secure audit trail
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6 mt-4 mt-sm-0 ani-in show-on-scroll">
                    <div className="card border-0 shadow-sm pricing-plan h-100">
                      <div className="card-body text-center p-5">
                        <div className="h4">Pro plan</div>
                        <p className="text-muted">
                          Start closing deals faster!
                        </p>
                        <div className="plan-price">${price}.00</div>
                        <p className="text-light-100">Per user / month</p>
                        <a
                          href="https://app.documentesign.com/auth/signup"
                          target="_blank"
                          className="btn btn-primary btn-block my-4"
                        >
                          Try for free
                        </a>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            Unlimited documents
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            Unlimited templates
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            Multiple signers
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            Secure audit trail
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                          <div>
                            <svg
                              width="13px"
                              height="10px"
                              viewBox="0 0 13 10"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title>pricing-checked</title>
                              <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Desktop-HD"
                                  transform="translate(-392.000000, -753.000000)"
                                  fill="#5CC9A7"
                                  fillRule="nonzero"
                                >
                                  <path
                                    d="M395.882136,762.235712 L404.080141,754.037733 C404.208261,753.909587 404.208261,753.701839 404.080141,753.573665 L403.770762,753.264313 C403.642642,753.136166 403.434894,753.136166 403.306747,753.264313 L395.650115,760.920945 L392.943224,758.214054 C392.815077,758.085934 392.607329,758.085934 392.479182,758.214054 L392.16983,758.523406 C392.04171,758.651526 392.04171,758.859301 392.16983,758.987448 L395.418094,762.235712 C395.546241,762.363859 395.753989,762.363859 395.882136,762.235712 Z"
                                    id="pricing-checked"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="plan-feature pl-3 text-light-100">
                            Premium support
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-shape d-none d-lg-block">
            <svg
              width="2560px"
              height="100px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              preserveAspectRatio="none"
              x="0px"
              y="0px"
              viewBox="0 0 2560 100"
              xmlSpace="preserve"
              enableBackground="new 0 0 2560 100"
            >
              <polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        <div className="container py-5 px-6 pricing-faqs">
          <h2>Our Story</h2>

          <p className="mt-4">
            Document eSign was an enterprise-grade Sinature solution that was
            established in 2020. Since its inception, Document eSign has been
            empowering individuals and businesses to digitize their workflows,
            approve processes, eliminate mistakes, integrate signatures easily
            into the apps and create a productive team that scales with your
            process.
          </p>
          <p className="mt-4">
            The thought behind Document eSign is helping both little and huge
            organizations take the digital leap. A lot of organizations hesitate
            when it comes to managing and handling sensitive information
          </p>
          <p className="mt-4">
            {" "}
            for example, agreements and business solutions in the cloud — this
            is the reason we committed ourselves to building a highly secure and
            well monitored e-Signature platform that is ahead of its time in
            terms of functionality while getting flexible work processes and an
            increase in time efficiency for large companies at the same time.
          </p>
          <p className="mt-4">
            The pre-pandemic world saw a time that had compelled us to remain
            distant from everyone at sight. The trying times did make us realize
            the value of every minute for business deals that were paused only
            for a mere signature. Although the use of digital signatures made
            its entry back in the day, the impact and the need of it was never
            felt this much before. It is then that every company started to put
            faith in the legality of the digital signatures that most of the
            nation states have law for.
          </p>
          <p className="mt-4">
            As for us, we always had to be dependent on a esignature solutions
            that help us close deals with our clients across the globe. With the
            pandemic affecting every corner of the globe, the business world
            started to take a serious hit economically with limited
            opportunities to grow. With fewer sales caving in, several
            organizations had started raising the prices of their products to
            make their ends meet. At this point every other eSignature solution
            in the market started to look more complicated or expensive.
          </p>
          <p className="mt-4">
            So, we decided to create our free esignature solution and offer it
            to anyone for absolutely free. Why? We know that each dollar counts
            in the trying times when you&apos;re a startup and we believe every
            team should have a window for opportunities from around the globe
            without having to pay a fortune. This was our time to give back
            something to the world we lived in when it needed us the most.
          </p>

          <h2>How we keep Document eSign free?</h2>
          <p className="mt-4">
            One of the questions that we receive all the time is: &quot; How do
            you make money if the service is free? What&apos;s the catch?&quot;
          </p>
          <p className="mt-4">
            Well, our free plans help us market our product more effectively
            which eventually increases our scope. We have paid{" "}
            <Link to="/api-integration/" className="ani-link">
              {" "}
              API gateways{" "}
            </Link>{" "}
            that have additional features aimed towards professionals.
          </p>
          <p className="mt-4">
            When something good is offered for free, like Document eSign, it
            usually comes with a hidden price - most usually it&apos;s privacy,
            something the tech industry is known for. However, that&apos;s not
            the case with Document eSign.
          </p>
          <p className="mt-4">
            We never sell or share any data with anyone. Why? Because we don
            &apos;t have to. We don &apos;t have investors or shareholders
            hungry for profit, so we don &apos;t face pressure to make a quick
            buck by any means necessary. And, because of good word of mouth, all
            our growth is organic. Our company is completely independent and
            free to do the right thing.
          </p>
          <p className="mt-4">
            How can we afford this? First, we make Document eSign accessible to
            everyone, and then we provide even more value to those on a paid API
            subscription:{" "}
          </p>
          <ul>
            <li>
              The basic version is free, so anyone can easily get started.
            </li>
            <li>
              We only charge for API as Some teams decide to use paid API
              gateways so they can access some extra features they need
            </li>
          </ul>

          <h2>Who is behind Document eSign?</h2>
          <p className="mt-4">
            Document eSign is a product built and maintained by{" "}
            <a
              href="https://www.angularminds.com"
              target="_blank"
              className="ani-link"
            >
              Angular Minds
            </a>
            , the company behind some of the most popular mobile and development
            applications, SaaS products and APIs currently on the market. We are
            an India-based IT company currently looking at over millions daily
            active users across our platforms.
          </p>
          <div className="row py-lg-5">
            <div className="col-lg-10 offset-lg-1">
              <h2 className="ani-in show-on-scroll mt-lg-5">
                Frequently asked questions
              </h2>
              <div className="accordion mt-4 faqs mb-2" id="price-faqs">
                <div className="card rounded-0 ani-in show-on-scroll">
                  <div
                    className="card-header bg-white border-0 py-2 px-0"
                    id="faq-01"
                  >
                    <div className="h4 mb-0">
                      <button
                        className="btn px-0 btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-01"
                        aria-expanded="true"
                        aria-controls="collapse-01"
                      >
                        Is Document eSign really free?
                      </button>
                    </div>
                  </div>

                  <div
                    id="collapse-01"
                    className="collapse show"
                    aria-labelledby="faq-01"
                    data-parent="#price-faqs"
                  >
                    <div className="card-body px-0 pt-0 text-light-100">
                      Yes, Document eSign is 100% free for all users, we offers
                      a free esign feature that enables users to electronically
                      sign an unlimited number of digital documents. No credit
                      card required.
                    </div>
                  </div>
                </div>
                <div className="card rounded-0 ani-in show-on-scroll">
                  <div
                    className="card-header bg-white border-0 py-2 px-0"
                    id="faq-02"
                  >
                    <div className="h4 mb-0">
                      <button
                        className="btn px-0 btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-02"
                        aria-expanded="false"
                        aria-controls="collapse-02"
                      >
                        What documents are supported?
                      </button>
                    </div>
                  </div>
                  <div
                    id="collapse-02"
                    className="collapse"
                    aria-labelledby="faq-02"
                    data-parent="#price-faqs"
                  >
                    <div className="card-body px-0 pt-0 text-light-100">
                      You can upload and e-sign any type of document pdf, doc,
                      docx, jpg; jpeg, xml.
                    </div>
                  </div>
                </div>
                <div className="card border-0 rounded-0 ani-in show-on-scroll">
                  <div
                    className="card-header bg-white border-0 py-2 px-0"
                    id="faq-03"
                  >
                    <div className="h4 mb-0">
                      <button
                        className="btn px-0 btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-03"
                        aria-expanded="false"
                        aria-controls="collapse-03"
                      >
                        How long does Document eSign store my documents?
                      </button>
                    </div>
                  </div>
                  <div
                    id="collapse-03"
                    className="collapse"
                    aria-labelledby="faq-03"
                    data-parent="#price-faqs"
                  >
                    <div className="card-body px-0 pt-0 text-light-100">
                      Draft envelopes are stored in your account for 30 days
                      from the date they were created. Signed or Cancelled
                      documents will be automatically removed after 30 days.
                    </div>
                  </div>
                </div>
                <div className="card border-0 rounded-0 ani-in show-on-scroll">
                  <div
                    className="card-header bg-white border-0 py-2 px-0"
                    id="faq-04"
                  >
                    <div className="h4 mb-0">
                      <button
                        className="btn px-0 btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-04"
                        aria-expanded="false"
                        aria-controls="collapse-04"
                      >
                        When do we remove the user`&apos;s account?
                      </button>
                    </div>
                  </div>
                  <div
                    id="collapse-04"
                    className="collapse"
                    aria-labelledby="faq-04"
                    data-parent="#price-faqs"
                  >
                    <div className="card-body px-0 pt-0 text-light-100">
                      If a user discontinues using his or her account for 12
                      consecutive months then our software automatically marks
                      them inactive.
                    </div>
                  </div>
                </div>
                <div className="card border-0 rounded-0 ani-in show-on-scroll">
                  <div
                    className="card-header bg-white border-0 py-2 px-0"
                    id="faq-05"
                  >
                    <h4 className="mb-0">
                      <button
                        className="btn px-0 btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-05"
                        aria-expanded="false"
                        aria-controls="collapse-05"
                      >
                        Is Document eSign available worldwide?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapse-05"
                    className="collapse"
                    aria-labelledby="faq-05"
                    data-parent="#price-faqs"
                  >
                    <div className="card-body px-0 pt-0 text-light-100">
                      Yes, individuals and businesses use Document eSign around
                      the world.
                    </div>
                  </div>
                </div>
                <div className="card border-0 rounded-0 ani-in show-on-scroll">
                  <div
                    className="card-header bg-white border-0 py-2 px-0"
                    id="faq-06"
                  >
                    <h4 className="mb-0">
                      <button
                        className="btn px-0 btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-06"
                        aria-expanded="false"
                        aria-controls="collapse-06"
                      >
                        Is Document eSIgn secure?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapse-06"
                    className="collapse"
                    aria-labelledby="faq-06"
                    data-parent="#price-faqs"
                  >
                    <div className="card-body px-0 pt-0 text-light-100">
                      Yes, Document design is completely safe and secure. The
                      company uses bank-grade AES-256 encryption with a secure
                      key management system — something the majority of banking
                      and financial institutions, as well as government
                      agencies, use for their web applications.
                    </div>
                  </div>
                </div>
                <div className="card border-0 rounded-0 ani-in show-on-scroll">
                  <div
                    className="card-header bg-white border-0 py-2 px-0"
                    id="faq-07"
                  >
                    <h4 className="mb-0">
                      <button
                        className="btn px-0 btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse-07"
                        aria-expanded="false"
                        aria-controls="collapse-07"
                      >
                        Is Document eSign legally binding?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapse-07"
                    className="collapse"
                    aria-labelledby="faq-07"
                    data-parent="#price-faqs"
                  >
                    <div className="card-body px-0 pt-0 text-light-100">
                      Yes, Document eSign is legally binding. We take security
                      quite seriously, meaning that its built-in esignature
                      technology needs to be legally binding for the company to
                      proceed with its operations.
                    </div>
                  </div>
                </div>
              </div>
              <div className="ani-in show-on-scroll">
                <small className="text-light-100">
                  Still have questions?{" "}
                  <Link to="/contact/" className="ani-link">
                    Get in touch
                  </Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Pricing
